<template>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>
import { setLocalData } from './common/jssdk';
import { newsUrl } from '@/unified';
import dayjs from 'dayjs';
import { EventBus } from '@/common/event-bus.js';
export default {
  name: 'app',
  data() {
    return { adUrls: [], channelId: '302935' };
  },
  async created() {
  },
  mounted() {
    document.addEventListener('visibilitychange', this.handleVisiable);
  },
  methods: {
    handleVisiable(e) {
      switch (e.target.visibilityState) {
        case 'prerender':
          console.warn('网页预渲染，内容不可见');
          break;
        case 'hidden':
          console.warn('内容不可见，处理后台、最小化、锁屏状态');
          break;
        case 'visible':
          console.warn('处于正常打开');
          EventBus.$emit('PageShow', '触发的');
          break;
      }
    },
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}
/* body {
  background-color: #f3f4f5;
} */
*,
:after,
:before {
  -webkit-tap-highlight-color: transparent;
}
body,
button,
input,
select,
textarea {
  font-size: 16px;
  line-height: 1.5;
  color: #000;
}
.van-dialog__header {
  font-weight: 600;
}
</style>
