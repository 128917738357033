import { useCtcode } from '@/utils/use-ctcode'
const ctCode = useCtcode()

export function useAppUserId() {

  // 获取当前的 appUserId
  function getAppUserId() {
    return localStorage.getItem('appUserId')
  }

  // 替换 appUserId
  function replaceAppUserId(newAppUserId) {
    // 比较当前缓存的 appUserId
    const oldAppUserId = getAppUserId()
    if (oldAppUserId !== newAppUserId) {
      console.log(`当前缓存的 appUserId 与新的 appUserId 不一致，将清除缓存的 appUserId 并重新设置新的 appUserId...oldAppUserId: ${oldAppUserId}, newAppUserId: ${newAppUserId}`)
      clearCtAppUserId(oldAppUserId)

      // 重新设置 appUserId
      localStorage.setItem('appUserId', newAppUserId)
    } else {
      console.log(`当前缓存的 appUserId 与新的 appUserId 一致，无需替换...oldAppUserId: ${oldAppUserId}, newAppUserId: ${newAppUserId}`)
    }
  }

  // 清除 计次票 appUserId 相关缓存
  function clearCtAppUserId(appUserId) {
    // 清除计次票缓存的用户码值
    const ctCodeKey = ctCode.getCtCodeKey()
    localStorage.removeItem(ctCodeKey)

    // 清除计次票缓存的上次进出站信息
    const getCtCodeLastStationKey = ctCode.getCtCodeLastStationKey()
    localStorage.removeItem(getCtCodeLastStationKey)

    console.log(`清除计次票缓存的用户码值和上次进出站信息成功...appUserId: ${appUserId}`)
  }

  return {
    replaceAppUserId,
    clearCtAppUserId,
    getAppUserId,
  }
}