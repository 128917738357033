import axios from 'axios';
import { baseUrl, version, appletId, repayment } from '@/unified';
import { Dialog, Toast } from 'vant';
import { getCode } from '@/utils/api';
import { EventBus } from '@/common/event-bus.js';
import { resetConfig } from '@/common/config.js';
import { useAppUserId } from '@/utils/use-app-user-id';

const appUserIdService = useAppUserId();
// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  baseURL: baseUrl, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 300000, // request timeout
});
const getOpenId = function() {
  console.log('获取openId');
  var upgrade = getUrlKey('upgrade');
  console.log('upgrade', upgrade);
  return new Promise(resolve => {
    upsdk.pluginReady(() => {
      upsdk.appletAuth({
        success(data) {
          axios({
            url: baseUrl + '/dtb/user/getOpenId',
            method: 'post',
            data: JSON.stringify({
              code: data.code,
              version: version,
              upgrade: upgrade ? upgrade : '',
            }),
            headers: {
              'Content-Type': 'application/json',
            },
          }).then(res => {
            console.log('getOpenId返回', res);
            console.log('getOpenId返回', res.data.status);
            if (res.data.status === 9999) {
              localStorage.setItem('openId', res.data.data.openId);
              appUserIdService.replaceAppUserId(res.data.data.appUsrId);
              localStorage.setItem('enableHCXAd', res.data.data.enableHCXAd == '1' ? '1' : '0');
              localStorage.setItem('isNewUser', res.data.data.isNewUser);
              localStorage.setItem('contractSt', res.data.data.contractSt);
              localStorage.setItem('nickName', res.data.data.phoneNo);
              localStorage.setItem('mapWhiteList', res.data.data.mapWhiteList);
              //localStorage.setItem('mapKey', res.data.data.mapKey);
              //if (res.data.data.followSt) {
              localStorage.setItem('followSt', res.data.data.followSt);
              localStorage.setItem('textBottomQrcode', res.data.data.textBottomQrcode);
              localStorage.setItem('dtbUsrId', res.data.data.dtbUsrId);
              //}
              resetConfig(res.data.config);
              resolve(res.data.data.openId);

              EventBus.$emit('configLoaded', res.data);
            } else {
              Toast(res.data.msg);
            }
          }).catch(err => {
            console.log(err)
          });
        },
        fail(error) {
          console.log('error', error);
        },
      });
    });
  });
};

const sendOriginalRequest = function(url, data) {
  return new Promise(resolve => {
    axios({
      url: url,
      method: 'post',
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(res => {
      resolve(res);
    }).catch(err => {
      console.log(err)
    });
  });
};
const getUrlKey = function(name, url) {
  url = url ? url : location.href;
  console.log('name: ' + name + ', href: ' + url);
  return (
    decodeURIComponent(
      (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(url) || [, ''])[1].replace(
        /\+/g,
        '%20',
      ),
    ) || null
  );
};

// request interceptor
service.interceptors.request.use(
  async config => {
    var data = config.data;
    if (typeof data === 'string') {
      data = JSON.parse(data);
    }
    for (var key in data) {
      if (key == 'openId' && (data[key] == null || !data[key])) {
        console.log('openId为null');
        const res = await getOpenId();
        console.log('获取到的openId', res);
        data[key] = res;

        break;
      } else {
      }
    }

    if (!data) {
      data = {};
    }
    data.version = version;
    var upgrade = await getUrlKey('upgrade');
    if (upgrade) {
      data.upgrade = upgrade;
    }
    config.data = JSON.stringify(data);

    console.log('最终config', config);
    // do something before request is sent
    return config;
  },
  error => {
    // do something with request error
    console.log('请求报错信息', error); // for debug
    return Promise.reject(error);
  },
);

// response interceptor
service.interceptors.response.use(
 
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  async response => {
    //console.log('服务响应header', response.headers)
    console.log('response', response);
    let res = response.data;
    console.log(res);

    // 服务端返回421错误时，如果客户端为老版本，不支持处理421错误，会导致兼容服务端的老版本客户端业务流程终端
    // 为尽量兼容老版本客户端，不影响用户体验，服务端在需要客户端升级时，会在响应数据中返回upgradeVer字段
    // upgradeVer=0表示不需要升级，upgradeVer!=0，表示需要升级到的版本号
    // upgradeVer!=与421错误等效，但优于421可同时返回业务处理结果，兼容不支持处理421的老版本客户端
    // 响应样例:
    // {
    //   status: 9999,
    //   upgradeVer: 15,
    //   ....
    // }

    var upgradeVer = res ? res.upgradeVer : 0;

    if (res.status === 421 || upgradeVer) {
      var upgrade = await getUrlKey('upgrade');
      var newVersion = upgradeVer ? upgradeVer : res.data; // upgradeVer>0时，取upgradeVer作为目标版本号
      console.log('upgrade', upgrade);
      console.log('version', version);
      console.log('newVersion', newVersion);
      var params = 'upgrade=' + newVersion;
      if (upgrade) {
        //如果地址栏中有upgrade参数
        if (version < newVersion && upgrade < newVersion) {
          console.log('参数的版本和地址中的版本都低于响应的版本');
          window.location.href = repayment + params;
          throw new Error();
        }
      } else {
        //地址栏中没有upgrade字段,请求中的版本又低于响应返回的版本还是要升级
        if (version < newVersion) {
          window.location.href = repayment + params;
          throw new Error();
        }
      }
    } else if (res.status === 401) {
      // var originalUrl = response.request._url;
      var originalUrl = '';
      if(!response.request._url){
        originalUrl = response.config.url;
      }else{
        originalUrl = response.request._url;
      }
      console.log('response', response);
      console.log('originalUrl: ' + originalUrl + ', response.data: ' + res.data);
      if (res.data) {
        const getOpenIdRes = await getOpenId();
        console.log('getOpenIdRes', getOpenIdRes);

        var originalData = JSON.parse(res.data);
        console.log('originalData',originalData)
        console.log('_resendReq',originalData['_resendReq'])
        if (!originalData['_resendReq']) {
          originalData['_resendReq'] = 1;
          originalData['openId'] = getOpenIdRes;
          if (originalUrl && originalUrl.indexOf('openId=') > 0) {
            var oldOpenId = getUrlKey('openId', originalUrl);
            originalUrl = originalUrl.replace(oldOpenId, getOpenIdRes);
            console.log('oldOpenId: ' + oldOpenId + ', replacedUrl: ' + originalUrl);
          }

          res = await sendOriginalRequest(originalUrl, originalData);

          console.log('重发请求后的结果', res);
          res = res.data;
        } else {
          console.warn('已重发请求，不再重发');
        }
      } else {
        console.warn('无原请求数据，不重发请求');
      }
    }

    return res;
  },
  error => {
    console.log('收到服务响应错误', error); // for debug

    return Promise.reject(error);
  },
);
export default service;
