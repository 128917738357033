/**
 * state
 */

export const state = {
  //获取openId信息
  CodeInfo: {},
  // 授权标识
  auth: false,
  // openId
  openId: '',
  isAbnormal:false,//是否生码异常
  sameStationInOutShow:false,
  prohibit:false,//禁码,
  status:'',//生码状态值
  msg:'',//生码异常返回消息
  // **列表
  list: [],
  // ** 单程票查询
  ticket: [],

  // 二维码信息
  queryInfo: {},
  // 进出站信息上报
  logAdd: false,
  // 日票
  // 首页状态判断
  dayQrcodeInfo: {}, // 申码信息
  dayOrAsingle: false, // 判断当前使用是单程票还是日票，单程票f，日票t
  
  // 计次票信息
  ctCodeInfo: {},
  ctCodeStatus: '',
  ctCodeMsg: '',
};
